<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="bg-secondary circle-badge-secondary d-flex justify-content-center align-items-center">
      <slot></slot>
    </div>
    <h6 class="my-3 text-center">{{ title }}</h6>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style></style>
