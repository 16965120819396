<template>
  <div v-show="show">
    <div class="fixed-bottom p-3 bg-primary text-center">
      <router-link to="/register">
        <h6 class="text-white mb-0">{{ $t('apply_loan') }}</h6>
      </router-link>
    </div>
    <div class="p-3 bg-primary text-center">
      <h6 class="text-white mb-0">{{ $t('apply_loan') }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        this.show = true
      } else {
        this.show = false
      }
    },
  },
}
</script>

<style></style>
