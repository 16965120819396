<template>
  <div class="d-flex justify-content-end align-items-center" style="z-index: 1025">
    <b-avatar :size="avatar_size" :src="currentImage"></b-avatar>
    <b-dropdown id="dropdown-right" ref="dropdown" right no-caret block variant="transparent" style="padding: 0">
      <template slot="button-content">
        <i class="ri-arrow-drop-down-line ri-2x text-white"></i>
      </template>
      <b-dropdown-item
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        href="#"
        :value="lang"
        @click="changeLanguage(lang)"
      >
        <b-avatar class="d-inline-block" :src="lang.image_path" size="2rem"></b-avatar>
        <div class="d-inline-block px-2">{{ lang.name }}</div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'LanguageDropdown',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    avatar_size: {
      type: String,
      default: '2rem',
    },
  },
  data() {
    return {
      langs: [
        {
          name: 'Myanmar',
          short_term: 'mm',
          image_path: require('@/assets/images/myanmar.png'),
        },
        {
          name: 'English',
          short_term: 'en',
          image_path: require('@/assets/images/english.png'),
        },
        {
          name: 'Thai',
          short_term: 'th',
          image_path: require('@/assets/images/thailand.png'),
        },
      ],
    }
  },
  computed: {
    locale() {
      return this.$store.getters['lang/locale']
    },
    currentImage() {
      return require('@/assets/images/' + this.locale + '.png')
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    changeLanguage(lang) {
      this.$store.dispatch('lang/setLocale', {
        locale: lang.short_term,
      })
    },
    handleScroll() {
      this.$refs.dropdown.hide(true)
    },
  },
}
</script>

<style></style>
