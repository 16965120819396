<template>
  <div class="p-0">
    <b-container fluid class="p-0">
      <!-- Header -->
      <div ref="header" class="col-12 m-0 p-0">
        <div class="banner-height">
          <div class="position-absolute h-100 w-100 object-fit-cover">
            <!-- <b-carousel
              id="carousel"
              class="banner-height d-block d-lg-none"
              controls
              indicators
              :interval="5000"
            >
              <b-carousel-slide
                v-for="(image, index) in sliderImages"
                :key="index"
                :img-src="image"
                class="banner-height"
              ></b-carousel-slide>
            </b-carousel> -->
            <b-carousel id="carousel" class="banner-height" controls indicators :interval="5000">
              <b-carousel-slide
                v-for="(sliderImage, index) in sliderLargeImages"
                :key="index"
                :img-src="sliderImage"
                class="banner-height"
              ></b-carousel-slide>
            </b-carousel>
          </div>
          <div class="container" fluid="lg p-0 m-0">
            <div class="py-3">
              <div class="d-flex justify-content-end align-items-center">
                <language-dropdown avatar_size="2.5rem"></language-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center py-3">
        <router-link to="/register">
          <b-button pill variant="primary" class="px-3 px-md-5 mx-1">
            <div>{{ $t('apply_loan') }}</div>
          </b-button>
        </router-link>
        <router-link to="/login">
          <b-button pill variant="primary" class="px-3 px-md-5 mx-1">
            {{ $t('existing_user') }}
          </b-button>
        </router-link>
      </div>
      <!-- Header -->
    </b-container>
    <div class="container" fluid="lg">
      <div class="row">
        <div class="col-12 px-4 pt-4">
          <h5>{{ $t('service_procedure') }}</h5>
        </div>
        <div class="row m-4">
          <div class="col-3" @click="goToServiceProcedure('1')">
            <circle-outline-icon :title="$t('fill_in_online_application') + ' + ' + $t('upload_documents')">
              <i class="text-secondary ri-file-list-2-line ri-custom-small"></i>
            </circle-outline-icon>
          </div>

          <div class="col-6">
            <div class="row">
              <div class="col-3 circle-outline d-flex justify-content-center align-items-center">
                <i class="text-secondary ri-arrow-right-fill ri-2x"></i>
              </div>

              <div class="col-6" @click="goToServiceProcedure('2')">
                <circle-outline-icon :title="$t('book_online_interview')">
                  <i class="text-secondary ri-calendar-line ri-custom-small"></i>
                </circle-outline-icon>
              </div>

              <div class="col-3 circle-outline d-flex justify-content-center align-items-center">
                <i class="text-secondary ri-arrow-right-fill ri-2x"></i>
              </div>
            </div>
          </div>

          <div class="col-3" @click="goToServiceProcedure('3')">
            <circle-outline-icon :title="$t('visit_branch') + ' + ' + $t('receive_funds')">
              <i class="text-secondary ri-building-line ri-custom-small"></i>
            </circle-outline-icon>
          </div>
        </div>

        <!-- Get to know us -->
        <div class="row w-100 m-0">
          <div class="col-12 p-4">
            <h5>{{ $t('get_to_know_us') }}</h5>
          </div>
          <div class="col-6 col-md-2" @click="goToGetToKnowUs('who_is_synpitarn')">
            <circle-icon :title="$t('who_is_synpitarn')">
              <i class="ri-group-line ri-custom"></i>
            </circle-icon>
          </div>
          <div class="col-6 col-md-2" @click="goToGetToKnowUs('our_loans')">
            <circle-icon :title="$t('our_loans')">
              <i class="ri-profile-line ri-custom"></i>
            </circle-icon>
          </div>
          <div class="col-6 col-md-2" @click="goToGetToKnowUs('core_principles')">
            <circle-icon :title="$t('core_principles')">
              <i class="ri-auction-line ri-custom"></i>
            </circle-icon>
          </div>
          <div class="col-6 col-md-2" @click="goToGetToKnowUs('do_you_qualified')">
            <circle-icon :title="$t('do_you_qualified')">
              <i class="ri-award-line ri-custom"></i>
            </circle-icon>
          </div>
          <div class="col-6 col-md-2" @click="goToFaq()">
            <circle-icon :title="$t('faqs')">
              <i class="ri-question-mark ri-custom"></i>
            </circle-icon>
          </div>
          <div class="col-6 col-md-2" @click="goToAnnoucements()">
            <circle-icon :title="$t('announcements')">
              <i class="ri-file-paper-2-line ri-custom"></i>
            </circle-icon>
          </div>
        </div>
        <!-- Get to know us -->

        <!-- get_to_know_our_customer -->
        <div ref="process" class="p-4">
          <h5 class="m-0 p-0">{{ $t('get_to_know_our_customer') }}</h5>
        </div>
        <div class="col-12 m-0 p-0">
          <b-aspect aspect="16:9">
            <iframe
              title="Syn Pitarn"
              src="https://www.youtube.com/embed/4rtZWo21NwM?&autoplay=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=4rtZWo21NwM"
              width="100%"
              height="100%"
              frameborder="0"
              encrypted-media
              allowfullscreen
            />
          </b-aspect>
        </div>
        <!-- get_to_know_our_customer -->

        <!-- Branches -->
        <div id="branch" ref="branch" class="col-12 m-0 p-0">
          <div class="p-4">
            <h5 class="m-0 p-0">{{ $t('service_avaliable_area') }}</h5>
          </div>
        </div>
        <!-- small size -->
        <div class="col-12 col-md-6 m-0 p-0 d-md-none">
          <b-aspect aspect="3:4">
            <gmap-map
              v-if="center"
              :zoom="14"
              :center="center"
              :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false,
              }"
              class="w-100 h-100"
            >
              <gmap-marker
                v-for="(branch, index) in branchList"
                :key="index"
                :position="{
                  lat: parseFloat(branch.latitude),
                  lng: parseFloat(branch.longitude),
                  zoom: 14,
                }"
                :icon="marker"
                @click="
                  center = {
                    lat: parseFloat(branch.latitude),
                    lng: parseFloat(branch.longitude),
                    zoom: 14,
                  }
                "
              ></gmap-marker>
            </gmap-map>
          </b-aspect>
        </div>
        <div class="col-12 col-md-6 m-0 p-0 d-md-none">
          <div style="position: relative">
            <div class="s-container">
              <div class="s-content">
                <div
                  v-for="(branch, index) in branchList"
                  :key="index"
                  class="s-body"
                  :position="{
                    lat: parseFloat(branch.latitude),
                    lng: parseFloat(branch.longitude),
                    zoom: 14,
                  }"
                >
                  <b-card>
                    <h6>{{ bindLocaleName(branch) }}</h6>
                    <div class="mt-4">
                      <div v-if="branch.phone" class="d-flex justify-content-center align-items-center">
                        <i class="ri-phone-fill ri-xl text-primary my-auto"></i>
                        <div class="small my-auto px-2 text-overflow">
                          <span v-for="(phone, i) in branch.phone.split(',')" :key="phone" @click="dial(phone)">
                            {{ phone }}
                            <span v-if="branch.phone.split(',').length != i + 1">,</span>
                          </span>
                        </div>
                      </div>
                      <div class="d-flex mt-4">
                        <i class="ri-map-pin-5-fill ri-xl text-primary"></i>

                        <div class="small my-auto px-2 text-overflow">
                          {{ branch.address_mm }}<br /><br />{{ branch.address }}
                        </div>
                      </div>
                      <div class="mt-4">
                        <b-button
                          class="w-100"
                          pill
                          variant="outline-primary"
                          @click="
                            center = {
                              lat: parseFloat(branch.latitude),
                              lng: parseFloat(branch.longitude),
                              zoom: 14,
                            }
                          "
                          >{{ $t('get_direction') }}</b-button
                        >
                      </div>
                    </div>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- small size -->
        <!-- large size -->
        <div class="col-12 col-md-6 m-0 p-0 d-none d-md-block">
          <b-aspect aspect="1:1">
            <gmap-map
              v-if="center"
              :zoom="14"
              :center="center"
              :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false,
              }"
              class="w-100 h-100"
            >
              <gmap-marker
                v-for="(branch, index) in branchList"
                :key="index"
                :position="{
                  lat: parseFloat(branch.latitude),
                  lng: parseFloat(branch.longitude),
                  zoom: 14,
                }"
                :icon="marker"
                @click="
                  center = {
                    lat: parseFloat(branch.latitude),
                    lng: parseFloat(branch.longitude),
                    zoom: 14,
                  }
                "
              ></gmap-marker>
            </gmap-map>
          </b-aspect>
        </div>
        <div class="col-12 col-md-6 m-0 p-0 d-none d-md-block">
          <div class="brand">
            <div class="brand-ratio">
              <div
                v-for="(branch, index) in branchList"
                :key="index"
                class="m-4"
                :position="{
                  lat: parseFloat(branch.latitude),
                  lng: parseFloat(branch.longitude),
                  zoom: 14,
                }"
              >
                <b-card>
                  <h6>{{ bindLocaleName(branch) }}</h6>
                  <div class="mt-4">
                    <div v-if="branch.phone" class="d-flex justify-content-center align-items-center">
                      <i class="ri-phone-fill ri-xl text-primary my-auto"></i>
                      <div class="small my-auto px-2 text-overflow">
                        <span v-for="(phone, i) in branch.phone.split(',')" :key="phone" @click="dial(phone)">
                          {{ phone }}
                          <span v-if="branch.phone.split(',').length != i + 1">,</span>
                        </span>
                      </div>
                    </div>
                    <div class="d-flex mt-4">
                      <i class="ri-map-pin-5-fill ri-xl text-primary"></i>

                      <div class="small my-auto px-2 text-overflow">
                        {{ branch.address_mm }}<br /><br />{{ branch.address }}
                      </div>
                    </div>
                    <div class="mt-4">
                      <b-button
                        class="w-100"
                        pill
                        variant="outline-primary"
                        @click="
                          center = {
                            lat: parseFloat(branch.latitude),
                            lng: parseFloat(branch.longitude),
                            zoom: 14,
                          }
                        "
                        >{{ $t('get_direction') }}</b-button
                      >
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
        <!-- Branches -->
      </div>
    </div>

    <Footer></Footer>
    <bottom-bar></bottom-bar>
  </div>
</template>
<script>
import LanguageDropdown from '@/components/LanguageDropdown.vue'
import CommonService from '@/api/service/CommonService'
import AccountService from '@/api/service/AccountService'
import CircleIcon from '@/components/CircleIcon.vue'
import CircleOutlineIcon from '@/components/CircleOutlineIcon.vue'
import Footer from '@/components/Footer.vue'
import BottomBar from '@/components/BottomBar.vue'

import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
  components: {
    LanguageDropdown,
    CircleIcon,
    CircleOutlineIcon,
    Footer,
    BottomBar,
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      marker: {
        url: require('@/assets/images/marker.png'),
      },
      image: {
        banner: require('@/assets/images/banner.png'),
        smileGirl: require('@/assets/images/smilegirl.png'),
        whatwedo: require('@/assets/images/whatwedo.png'),
        marker: require('@/assets/images/marker.png'),
      },
      center: null,
      branchList: [],
      service_procedures: [
        {
          step: 'step_one',
          title: 'fill_in_online_application',
          icon: 'ri-file-list-2-line',
          icon_color: 'text-primary-50',
          bg_color: 'bg-primary-50',
          circle_color: null,
        },
        {
          step: 'step_two',
          title: 'upload_documents',
          icon: 'ri-upload-cloud-line',
          icon_color: 'text-primary-60',
          bg_color: 'bg-primary-60',
          circle_color: 'bg-primary-50',
        },
        {
          step: 'step_three',
          title: 'book_online_interview',
          icon: 'ri-calendar-line',
          icon_color: 'text-primary-70',
          bg_color: 'bg-primary-70',
          circle_color: 'bg-primary-60',
        },
        {
          step: 'step_four',
          title: 'visit_branch',
          icon: 'ri-building-line',
          icon_color: 'text-primary-80',
          bg_color: 'bg-primary-80',
          circle_color: 'bg-primary-70',
        },
        {
          step: 'step_five',
          title: 'receive_funds',
          icon: 'ri-hand-coin-line',
          icon_color: 'text-primary-90',
          bg_color: 'bg-primary-90',
          circle_color: 'bg-primary-80',
        },
        {
          step: 'step_six',
          title: 'easy_repayments',
          icon: 'ri-heart-line',
          icon_color: 'text-primary',
          bg_color: 'bg-primary',
          circle_color: 'bg-primary-90',
        },
      ],
      sliderImages: [
        require('@/assets/images/banner1.jpeg'),
        require('@/assets/images/banner2.jpeg'),
        require('@/assets/images/banner3.jpeg'),
      ],
      sliderLargeImages: [
        require('@/assets/images/banner1.jpeg'),
        require('@/assets/images/banner2.jpeg'),
        require('@/assets/images/banner3.jpeg'),
      ],
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
      },
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    locale() {
      this.getBranch()
      this.changeLanguage()
    },
  },
  created() {
    this.getBranch()
    const refName = this.$route.params.refName
    if (refName) {
      this.$nextTick(() => {
        this.scroll(refName)
      })
    }
  },
  methods: {
    async getBranch() {
      try {
        const res = await CommonService.getBranch()
        if (res.status == 200) {
          const branchResponse = res.data.data
          branchResponse.forEach((branch) => {
            if (branch.status == 'show') {
              this.branchList.push(branch)
            }
          })

          if (this.branchList.length > 0) {
            this.center = {
              lat: parseFloat(this.branchList[0].latitude),
              lng: parseFloat(this.branchList[0].longitude),
              zoom: 14,
            }
          }
        }
      } catch (err) {
        this.showAlert(err)
      }
    },
    async changeLanguage() {
      if (this.$store.getters['auth/token']) {
        try {
          await AccountService.setLanguage()
        } catch (err) {
          console.log(err)
        }
      }
    },
    bindLocaleName(branch) {
      const locale = this.$i18n.locale
      switch (locale) {
        case 'mm':
          return branch.name_mm
        case 'en':
          return branch.name_en
        case 'th':
          return branch.name_th
        default:
          return branch.name_mm
      }
    },
    goToServiceProcedure(refName) {
      this.$router.push({
        name: 'service-procedure',
        params: {
          refName: refName,
        },
      })
    },
    goToGetToKnowUs(refName) {
      this.$router.push({
        name: 'get-to-know-us',
        params: {
          refName: refName,
        },
      })
    },
    goToFaq() {
      this.$router.push({
        name: 'faq',
      })
    },
    goToAnnoucements() {
      this.$router.push({
        name: 'annoucements',
      })
    },
    dial(number) {
      window.location = 'tel:' + number
    },
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
</script>
<style scoped></style>
